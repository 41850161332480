const required = require('validity-required')
const schemata = require('schemata')
const validateIfPropertyEquals = require('validity-validate-if-property-equals')
const { promisify } = require('util')
const usedVehicleSchema = require('../used-vehicle/schema')
const makeSchema = require('../make/schema')()
const modelSchema = require('../model/schema')
const dealershipSchema = require('../dealership/schema')()
const customValidityMessage = require('../../../lib/validators/custom-validator-message')
const createUniqueValidator = require('validity-unique-property')
const resolveImages = require('../../../lib/image-resolver')

module.exports = (serviceLocator, find) => {
  const uniqueValidator = customValidityMessage(
    createUniqueValidator(find, {}),
    'This {name} is already in use'
  )

  const ctaValidator = (key, name, object, cb) => {
    if (!object[key] || object[key].startsWith('http')) {
      cb()
    }

    uniqueValidator(key, name, object, cb)
  }

  const publishedValidator = validate =>
    validateIfPropertyEquals('state', 'Published', validate)

  const schema = schemata({
    name: 'Offer',
    properties: {
      _id: {
        type: String
      },
      account: {
        type: String,
        validators: [required]
      },
      type: {
        type: String,
        validators: [required]
      },
      usedVehicle: {
        type: Array,
        defaultValue: () => [],
        resolve: offer => {
          return (
            Promise.all(
              offer.usedVehicle
                ? offer.usedVehicle.map(vehicle =>
                    promisify(serviceLocator.usedVehicleService.read)(vehicle)
                  )
                : []
            ) || []
          )
        },
        resolveType:
          serviceLocator &&
          schemata.Array(
            usedVehicleSchema({
              usedVehicleFind: serviceLocator.usedVehicleService.findOne,
              readDealership: serviceLocator.dealershipService.cachedRead,
              readColour: serviceLocator.colourService.cachedRead,
              readMake: serviceLocator.makeService.cachedRead
            })
          )
      },
      make: {
        type: String,
        validators: [validateIfPropertyEquals('type', 'New', required)],
        resolveType: makeSchema,
        resolve: ({ make }) => serviceLocator.makeService.cachedRead(make)
      },
      model: {
        type: String,
        validators: [validateIfPropertyEquals('type', 'New', required)],
        resolveType: modelSchema(serviceLocator),
        resolve: ({ model }) => {
          return serviceLocator.modelService.cachedRead(model)
        }
      },
      heading: {
        type: String,
        validators: [required]
      },
      subheading: {
        type: String
      },
      sell: {
        type: String,
        validators: [validateIfPropertyEquals('sellOverride', true, required)]
      },
      body: {
        type: Object,
        defaultValue: () => ({ widgets: [] }),
        validators: {
          draft: [],
          published: [],
          archived: []
        },
        resolve: async offer => {
          if (!offer.body || !offer.body.widgets) return JSON.stringify({})
          return JSON.stringify(offer.body.widgets)
        },
        resolveType: String
      },
      callOut: {
        type: String
      },
      category: {
        type: String,
        validators: [required]
      },
      offerCategory: {
        type: Array,
        defaultValue: () => []
      },
      callToActionText: {
        type: String,
        validators: [validateIfPropertyEquals('type', 'General', required)]
      },
      callToActionLink: {
        type: String,
        validators: [
          validateIfPropertyEquals('type', 'General', required),
          publishedValidator(ctaValidator)
        ]
      },
      outlined: {
        type: Boolean,
        defaultValue: () => false
      },
      sellOverride: {
        type: Boolean,
        defaultValue: () => false
      },
      images: {
        type: Object,
        defaultValue: () => [],
        resolveType: Array,
        resolve: async offer => {
          if (
            (!offer.images ||
              !offer.images.widgets ||
              offer.images.widgets.length === 0) &&
            offer.type === 'New'
          ) {
            const model = await serviceLocator.modelService.cachedRead(
              offer.model
            )

            if (!model || !model.heroImages) return []

            return resolveImages(model.heroImages)
          }
          return resolveImages(offer.images)
        }
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date()
      },
      slug: {
        type: String,
        validators: [
          publishedValidator(
            validateIfPropertyEquals('callToActionLink', '', uniqueValidator)
          )
        ]
      },
      motabilityPrice: {
        type: String,
        resolveType: String,
        resolve: async offer => {
          const model = await serviceLocator.modelService.cachedRead(
            offer.model
          )

          if (!offer.model || !model) {
            return ''
          }

          if (offer.motabilityPrice) {
            return offer.motabilityPrice
          }

          return model.price || ''
        }
      },
      motabilityDownpayment: {
        type: String
      },
      dealerships: {
        type: Array,
        defaultValue: () => [],
        resolve:
          serviceLocator &&
          (parent =>
            Promise.all(
              (parent.dealerships || []).map(id =>
                promisify(serviceLocator.dealershipService.read)(id)
              )
            )),
        resolveType: serviceLocator && schemata.Array(dealershipSchema)
      },
      promoImages: {
        type: Object,
        defaultValue: () => [],
        resolveType: Array,
        resolve: offer => resolveImages(offer.promoImages)
      },
      liveDate: {
        type: Date
      },
      expiryDate: {
        type: Date
      },
      description: {
        type: String
      },
      termsAndConditions: {
        type: String
      },
      isMotability: {
        type: Boolean,
        resolveType: Boolean,
        resolve: async ({ offerCategory }) => {
          const category = await serviceLocator.offerCategoryService.cachedRead(
            offerCategory
          )
          return category && category.name.toLowerCase() === 'motability'
        }
      },
      discoverable: {
        type: Boolean,
        defaultValue: true
      },
      state: {
        type: String,
        options: ['Draft', 'Published'],
        defaultValue: 'Draft',
        validators: { all: [] }
      },
      order: {
        type: Number,
        defaultValue: 0
      },
      details: {
        type: Array,
        defaultValue: () => []
      },
      offerTitle: {
        type: String
      },
      offerDescription: {
        type: String
      },
      saleCategory: {
        type: String
      },
      dialogHeading: {
        type: String
      },
      dialogDescription: {
        type: String
      },
      dialogPrice: {
        type: String
      },
      dialogCtaLabel: {
        type: String
      },
      dialogCtaLink: {
        type: String
      }
    }
  })

  return schema
}
